// import { useEffect, useState } from "react"
// import {
//   initPushNotifications,
//   subscribeToInitialTopics,
//   unsubscribeFromTopics,
// } from "app/lib/firebase"
// import { useCurrentUser } from "app/lib/CurrentUser"
// import { useOrganization } from "app/common/Organization"
// import { useNotification } from "app/features/notification/NotificationContext"
// import Query from "app/common/Query"
// import { gql } from "@apollo/client"
// import { getEventState } from "app/features/events/EventUtils"

let PushNotifications = () => null

// export const QUERY = gql`
//   query Events($organizationId: ID!, $startedFrom: Time, $startedUntil: Time) {
//     organization(id: $organizationId) {
//       id
//       events(
//         startedFrom: $startedFrom
//         startedUntil: $startedUntil
//         order: ASC
//       ) {
//         edges {
//           node {
//             id
//             title
//             registered
//           }
//         }
//         pageInfo {
//           endCursor
//           hasNextPage
//         }
//       }
//     }
//   }
// `
//
// if (typeof window !== "undefined") {
//   PushNotifications = () => {
//     const { startedFrom, startedUntil } = useNotification()
//     const user = useCurrentUser()
//     const organization = useOrganization()
//     const { showNotification } = useNotification()
//
//     if (!organization) {
//       return null
//     }
//
//     const eventsQuery = (
//       <Query
//         query={QUERY}
//         variables={{
//           startedFrom,
//           startedUntil,
//           organizationId: organization.id,
//         }}
//         path={"organization.events"}
//         fetchPolicy="cache-first"
//       >
//         {({ data }) => {
//           if (!data || !data.organization) return null
//
//           const events = data.organization.events.edges.filter(
//             (item) => item.node.registered)
//
//           const eventList = events.map((item) => ({
//             "id" : item.node.id,
//             "state" : getEventState(item) }))
//
//           return (
//             <PushNotificationsInitializer
//               user={user}
//               organization={organization}
//               eventList={eventList}
//               showNotification={showNotification}
//             />
//           )
//         }}
//       </Query>
//     )
//
//     return eventsQuery
//   }
//
//   const PushNotificationsInitializer = ({
//     user,
//     organization,
//     eventList,
//     showNotification,
//   }) => {
//     const [orgId, setOrgId] = useState()
//
//     useEffect(() => {
//       window.removeEventListener("push-notification", (push) =>
//         showNotification({ notification: push.detail })
//       )
//       window.addEventListener("push-notification", (push) =>
//         showNotification({ notification: push.detail })
//       )
//     }, []) // eslint-disable-line react-hooks/exhaustive-deps
//
//     useEffect(() => {
//       const firebasePushNotificationsInitializer = async () => {
//         if (
//           user.isAuthenticated &&
//           (organization?.isChildless ?? false) &&
//           organization.id !== orgId
//         ) {
//           if (orgId) {
//             await unsubscribeFromTopics(user.id, orgId, eventList)
//           }
//
//           setOrgId(organization.id)
//
//           await initPushNotifications(showNotification)
//           await subscribeToInitialTopics(user.id, organization.id, eventList)
//         }
//       }
//
//       return firebasePushNotificationsInitializer().catch((error) => {
//         console.error("Unable to initialize Firebase", error)
//       })
//     }, [user, organization, eventList, orgId, showNotification])
//
//     return null
//   }
// }

export default PushNotifications
